.admin-upload-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background-color: black;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }ù
  .progress-bar-container {
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 5px;
  }
  
  .progress-bar {
    height: 20px;
    background-color: #4CAF50;
    border-radius: 5px;
  }
  
  
  .admin-upload-container form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .admin-upload-container input,
  .admin-upload-container textarea,
  .admin-upload-container button {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    color: black;
  }
  
  .admin-upload-container textarea {
    resize: vertical; /* Permet à l'utilisateur de redimensionner le textarea verticalement */
  }
  
  .admin-upload-container button {
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border: none;
  }
  
  .admin-upload-container button:hover {
    background-color: #0056b3;
  }
  
  /* Styles spécifiques pour les inputs de fichier pour les rendre plus intuitifs */
  .admin-upload-container input[type="file"] {
    background-color: white;
    border: 1px dashed #007bff;
    padding: 15px;
    text-align: center;
  }
  
  /* Petit conseil : style pour indiquer que le survol de l'input fichier est interactif */
  .admin-upload-container input[type="file"]:hover {
    background-color: #f0f0f0;
  }
  
  .admin-upload-container h4, h3{color: black !important;}


  .progress-bar-container {
    width: 100%; /* Prend toute la largeur de son conteneur */
    background-color: #e0e0e0; /* Couleur de fond du conteneur */
    border-radius: 8px; /* Arrondit les coins du conteneur */
    margin: 20px 0; /* Espacement autour du conteneur */
    height: 20px; /* Hauteur du conteneur */
    overflow: hidden; /* Assure que la barre de progression reste dans les limites du conteneur */
  }
  
  /* La barre de progression elle-même */
  .progress-bar {
    height: 100%; /* Prend toute la hauteur du conteneur */
    background-color: #4caf50; /* Couleur de la barre de progression */
    width: 0%; /* Largeur initiale de la barre de progression, sera ajustée dynamiquement */
    transition: width 0.4s ease; /* Transition douce pour les changements de largeur */
    border-radius: 8px; /* Arrondit les coins de la barre de progression */
  }