.update_password_container{
    h2 {
        color: rgba(var(--secondary-color), 0.87); /* Texte principal en blanc avec une opacité à 87% pour un meilleur contraste */
        font-size: 1.5rem; /* Taille adaptée pour les titres */
        margin-bottom: 2rem; /* Espacement pour séparer du contenu suivant */
      }
      
      form {
        display: flex;
        flex-direction: column;
        gap: 1rem; /* Espacement uniforme entre les éléments */
      }
      
      label {
        color: rgba(var(--secondary-color), 0.87); /* Couleur du texte des labels */
        display: flex;
        flex-direction: column;
        gap: 0.5rem; /* Petit espacement entre le label et l'input */
      }
      
      input[type="password"] {
        padding: 0.5rem;
        border: 1px solid rgba(var(--secondary-color), 0.42); /* Bordure subtile */
        border-radius: 4px; /* Arrondissement léger typique de Material UI */
        background-color: rgba(var(--secondary-color), 0.08); /* Fond légèrement distinct pour l'input */
        color: rgba(var(--secondary-color), 0.87); /* Texte de l'input */
      }
      
      input[type="password"]:focus {
        border-color: rgba(var(--secondary-color), 0.87); /* Bordure plus visible lors du focus */
      }
      
}

.confidentiality-container {
    padding: 2rem;
    /* Pas de background-color spécifié pour suivre votre instruction */
    margin: 2rem 0; /* Espacement autour du conteneur */
  }
  
  .confidentiality-container h2 {
    color: rgba(var(--secondary-color), 0.87); /* Utilisation de la couleur secondaire avec opacité pour le titre */
    font-size: 2rem; /* Augmentation de la taille pour mettre en avant le titre */
    margin-bottom: 1rem; /* Espacement sous le titre */
  }
  
  .confidentiality-container p {
    color: rgba(var(--secondary-color), 0.87); /* Utilisation de la couleur secondaire pour le texte */
    line-height: 1.5; /* Hauteur de ligne pour une meilleure lisibilité */
    margin-bottom: 1rem; /* Espacement entre les paragraphes */
  }

  button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px; /* Bouton arrondi */
    background-color: var(--accent-color); /* Fond du bouton */
    cursor: pointer; /* Curseur de clic */
    transition: background-color 0.3s; /* Transition douce pour l'interaction */
  }
  
  button:hover {
    background-color: rgba(var(--secondary-color), 0.7); /* Changement de couleur au survol */
  }

  .delete-account-container {
    padding: 2rem;
    /* Pas de background-color pour suivre vos instructions */
    margin: 2rem auto; /* Centrer le conteneur avec un espacement */
    max-width: 600px; /* Largeur maximale pour une meilleure lisibilité sur grands écrans */
  }
  
  .delete-account-container h2 {
    color: rgba(var(--alert-color), 0.87); /* Rouge pour attirer l'attention sur l'action de suppression */
    margin-bottom: 1rem; /* Espacement après le titre */
  }
  
  .delete-account-container p {
    color: rgba(var(--secondary-color), 0.87); /* Utilisation de la couleur secondaire pour le texte */
    line-height: 1.5; /* Hauteur de ligne pour une lisibilité optimale */
    margin-bottom: 1rem; /* Espacement entre les paragraphes */
  }
  
  .delete-account-container form label {
    display: flex;
    flex-direction: column;
    gap: 0.5rem; /* Espacement entre le texte du label et l'input */
    color: rgba(var(--secondary-color), 0.87); /* Texte du label */
  }
  
  .delete-account-container input[type="text"],
  .delete-account-container input[type="password"] {
    padding: 1rem;
    margin-bottom: 1rem;

    border: 1px solid rgba(var(--secondary-color), 0.42); /* Bordure discrète */
    border-radius: 4px; /* Coins arrondis pour l'esthétique */
    background-color: rgba(var(--secondary-color), 0.08); /* Légère distinction pour le champ de saisie */
    color: rgba(var(--secondary-color), 0.87); /* Couleur de texte adaptée */
  }
  
  .delete-account-container button {
    padding: 0.75rem 1.5rem;
    border-radius: 4px; /* Bordures arrondies pour le bouton */
    border: none;
    background-color: rgba(var(--alert-color), 0.87); /* Rouge pour le bouton de suppression */
    color: #fff; /* Texte blanc pour contraste élevé */
    cursor: pointer; /* Indique une action cliquable */
    transition: background-color 0.3s ease; /* Transition pour l'interaction */
  }
  
  .delete-account-container button:hover {
    background-color: rgba(var(--alert-color), 0.7); /* Légère modification au survol pour indiquer l'interaction */
  }

  .errortext{color: rgba(var(--alert-color), 1) !important;}


  .confidentiality-container h2,.confidentiality-container  h3,.confidentiality-container  h4 ,.confidentiality-container h5,.confidentiality-container  h6,.confidentiality-container  p,.confidentiality-container  ul .confidentiality-container li{
    color: rgba(var(--secondary-color), 1);
    }
