/* src/BottomBar.css */
.bottom-bar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: 1rem;
    left: 5% !important;
    right: 0;
    padding: 12px 0;
    margin-bottom: 2rem;
    width: 90%;
    border-radius: 25px;
   
    z-index: 1000;
    background-color: var(--accent-color);
  }
  
  .icon-button {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    transition: transform 0.2s ease; /* Transition douce pour l'interaction */
  }
  
  .icon-button img {
    width: 24px; /* Taille des icônes */
    height: auto;
  }
  
  .icon-button:focus,
  .icon-button:hover {
    transform: scale(1.1); /* Effet de zoom léger au survol ou au focus */
    background-color: rgba(0, 0, 0, 0.05); /* Légère interaction au survol */
  }
  
  .creator-name{color: white !important;font-weight: 700;}
  
  /* Adaptations pour les écrans de taille moyenne et petite */
  
  
  .menu-dropdown {
    position: absolute;
    background-color: --secondary-black !important;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    position: relative;
    padding-top: 6rem;
  }
  
  .menu-dropdown ul {
    list-style-type: none;
    padding: 0;
  }
  
  .menu-dropdown ul li {
    padding: 12px 16px;
    text-align: left;
    cursor: pointer;
    font-weight: 800;
    color: blue;
  }
  
  .menu-dropdown ul li:hover {
    background-color: --secondary-black;
  }
  