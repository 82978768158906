@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap');

.App {
  text-align: center;
  background-color: #090a0b;
  min-height: 100vh; /* S'assure que l'app occupe au minimum toute la hauteur de la vue */
 
  padding: 20px; /* Un peu d'espace autour du contenu pour ne pas toucher les bords */
  font-family: "Cabin", sans-serif;
  padding-top: 7rem;
}

h1{
font-weight: 700;
}
h1, h2, h3, h4, h5, h6, p, a, li, input, button, label, select, option, textarea{
  color: var(--text-color);
}

:root {
  --primary-color: 0, 0, 0;
  --secondary-color: 255, 255, 255;
  --accent-color: #6862ff;
  --alert-color: 255, 0, 0; /* Rouge */
  --secondary-black: #131517;
  --text-color: #ffffff;
}






