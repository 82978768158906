.video-view-container {
    text-align: center;color: black !important;
    padding-top: 5rem;
    padding-bottom: 8rem;
  }
  
  .video-title {
    font-weight: bold; color: rgba(var(--secondary-color));
  }
  
  .tab {
    display: flex; /* Utilisez flex pour répartir équitablement l'espace */
    background-color: rgba(var(--secondary-color), 0.1);
    border-radius: 15px; /* Bords arrondis en haut pour un look moderne */
    /* Une couleur de fond claire pour un aspect flat */
  }
  
  .tab button {
    flex: 1; /* Permet aux boutons de prendre toute la largeur disponible */
    background-color: transparent; /* Prend la couleur de fond du conteneur */
    border: none; /* Supprime les bords par défaut */
    outline: none; /* Supprime le contour lors de la sélection */
    cursor: pointer; /* Change le curseur pour indiquer qu'il est cliquable */
    padding: 14px 16px; /* Ajuste le padding pour une meilleure apparence */
    transition: background-color 0.3s; /* Transition douce pour le survol */
    font-size: 17px; /* Ajuste la taille de la police */
    border-radius: 10px;
   color: rgba(var(--secondary-color)) !important;

  }
  .margin1{
    margin-left: 5px;
    margin-right: 1px;

    margin-top: 5px;
    margin-bottom: 5px;
    /* Bords arrondis en haut pour un look moderne */
}
.margin2{
    margin-right: 5px;
    margin-left: 1px;

    margin-top: 5px;
    margin-bottom: 5px; /* Bords arrondis en haut pour un look moderne */
}
  
  .tab button:hover {
    background-color: #ddd; /* Couleur de fond au survol pour un feedback visuel */
  }
  
  .tab button.active {
    background-color: rgba(var(--secondary-color), 0.1); /* Prend la couleur de fond du conteneur */
    border-radius: 10px;
    margin: 5px; /* Bords arrondis en haut pour un look moderne */


    /* Couleur de fond pour l'onglet actif */
  }
  
  
  .tabcontent {
    display: none;
    padding: 6px 12px;
    border-top: none;
    text-align: left;
    border-radius: 10px;
    color: rgba(var(--primary-color)) !important;
    /* Prend la couleur de fond du conteneur */


  }.image-gallery {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Deux colonnes */
    gap: 20px; /* Espacement entre les images */
    padding: 20px; /* Padding autour de la galerie pour l'espacement */
    justify-items: center; /* Centre les images dans leurs cellules de grille */
  }
  
  .image-gallery img {
    width: 100%; /* Les images prennent toute la largeur de leur cellule de grille */
    max-width: 100px; /* Limite la largeur maximale de l'image pour le contrôle de la taille */
    height: auto; /* Garde le ratio de l'image */
    border-radius: 10px; /* Bords arrondis */
    object-fit: cover; /* Assure que les images couvrent l'espace dédié sans déformation */
  }
  
  
  .detail {
    background-color: var(--secondary-black) ; /* Légèrement plus clair que le fond principal */
    text-align: left;
    padding: 20px;
    margin-top: 20px;
    border-radius: 15px;
    color: rgba(var(--primary-color)) !important;

  }
  
  .payment-info {
    display: flex;
    justify-content: space-between;
    color: black

  }
  
  .purchase-button {
    background-color: var(--accent-color); /* Couleur verte pour le bouton */
    color: white;
    padding: 20px 30px;
    margin: 20px 0;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    display: block; /* Centre le bouton */
    margin-left: auto;
    margin-right: auto;
    font-size: 1.2rem;
    font-weight: bold;
    width: 50%;
  }
  
  .purchase-button:hover {
    background-color: #45a049;
  }
  
  .secure-payment {
    text-align: center;
    margin-top: 10px;
  }
  

  .duration{
    font-weight: bold;
    text-align: left;
    margin: 5px 0;
    color: rgba(var(--secondary-color)) !important;
  }
  .description{
    text-align: left;
    margin: 5px 0;
    color: rgba(var(--secondary-color)) !important;
  }


  .modal {
    position: fixed;
    z-index: 1050;
    left: 0;
    top: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px; /* Espace autour de l'image */
  }
  
  .modal img {
    max-width: calc(100% - 80px); /* Prend en compte le padding pour éviter tout débordement */
    max-height: calc(100% - 80px); /* Idem */
    margin: auto; /* Centre l'image */
  }
  
  .modal-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 30px;
    cursor: pointer;
    user-select: none;
    background: transparent;
    border: none;
  }
  
  .left-arrow {
    left: 20px;
  }
  
  .right-arrow {
    right: 20px;
  }
  
  .close-modal {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
    background: transparent;
    border: none;
  }
  