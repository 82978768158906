.admin-upload-pack {
    max-width: 800px;
    margin: auto;
    padding: 1rem;
  }
  
  .admin-upload-pack h2 {
    color: white;
    text-align: center;
  }
  
  .admin-upload-pack  .video-selection {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
    color: white;

  }
  
  .admin-upload-pack  .video-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .admin-upload-pack   .video-item input[type="checkbox"] {
    color: white;
  }
  
  .admin-upload-pack   .pack-details {
    display: grid;
    grid-gap: 1rem;
    margin-top: 1rem;
  }
  
  .admin-upload-pack  input[type="text"], input[type="number"], textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1rem;
  }
  
  .admin-upload-pack   input[type="text"]:focus, input[type="number"]:focus, textarea:focus {
    outline: none;
    border-color: #1976d2;
    box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
  }
  
  .admin-upload-pack   textarea {
    height: 120px;
    resize: vertical;
  }
  
  .admin-upload-pack  button[type="submit"] {
    width: 100%;
    padding: 0.75rem;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .admin-upload-pack  button[type="submit"]:hover {
    background-color: #388e3c;
  }
  