.financial {
    font-family: 'Roboto', sans-serif;
    max-width: 100%;
    padding: 20px;
    background-color: #f5f5f5;
  }
  
  .financial h3 {
    color: #1976d2;
    margin-bottom: 20px;
  }
  
  .financial .balance-info {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 15px;
    margin-bottom: 20px;
  }
  
  .financial .balance-info p {
    margin: 10px 0;
    color: #333;
    font-size: 1.5rem;
    font-weight: 700;
  }
  
  .financial .purchases {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .financial .purchase-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 15px;
  }
  
  .financial .purchase-card p {
    margin: 5px 0;
    color: #666;
    font-size: 14px;
  }
  
  .financial .purchase-card p span {
    font-weight: bold;
  }
  

  .status-succeeded {
    color: green;
  }
  
  .status-canceled {
    color: red;
  }
  .amount{font-size: 1.3rem; color: black !important;}